<template>
  <div>
    <Dialog
      class="pa-0 ma-0"
      :openDialog="openModal"
      :displayCloseBtn="true"
      @close="handleModalClose"
      icon="mdi-content-copy"
      title="Upload evidence"
    >
      <template slot="text">
        <v-container
          data-test="upload-evidence-dialog"
          class="px-6 pt-0"
        >
          <v-row>
            <v-col cols="12">
              <p class="body-1">
                Upload evidence of progress towards your goals.
              </p>
              <p>
                <a
                  href="https://education.nsw.gov.au/inside-the-department/human-resources/performance/school-teachers/classroom-teachers/evidence"
                  target="_blank"
                >
                  What type of evidence is good?
                </a>
                <br />
              </p>
              <p>
                Supported Files:
                <em>
                  Word and Excel documents, Images (JPEG and PNG), and PDF
                  documents.
                </em>
              </p>
              <p>
                Total storage used: {{ fileSizePrint(totalFileSize) }} MB / 20
                MB
              </p>
            </v-col>
          </v-row>
          <v-row v-if="showWarning">
            <v-col>
              <Alert
                type="warning"
                text="You have uploaded the maximum number of evidence files to your goal."
                subtext="Please work with your PDP supervisor to refine your goals."
                inPage
                :backgroundColour="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <AdsButton
                :disabled="showWarning"
                secondary
                id="uploader"
                @click="openSelectFile"
              >
                Choose file
              </AdsButton>
              <input
                type="file"
                :accept="ACCEPT_FILE_EXTENSIONS"
                hidden
                multiple
                ref="uploader"
                @change="onFileSelection"
              />
            </v-col>
            <v-col v-if="errorMessage !== null">
              <p class="subtitle-1 error-text">
                {{ errorMessage }}
              </p>
            </v-col>
            <v-col v-if="uploadSuccessful">
              Evidence uploaded successfully
            </v-col>
            <v-col
              cols="12"
              v-if="currentEvidence?.length > 0"
            >
              <h1 class="title mb-4 mt-2">Uploaded files</h1>
            </v-col>
            <v-col
              cols="12"
              v-if="currentEvidence?.length > 0"
            >
              <FileCard
                v-for="file in currentGoal.evidenceFiles"
                :key="`goal${goal?.id}-modal--evidencefile${file.id}`"
                :file="file"
                @downloadFile="$emit('downloadFile')"
                @deleteFile="$emit('deleteFile')"
                class="mb-6"
              />
            </v-col>
            <v-col
              cols="12"
              class="mt-4"
            >
              <AdsButton
                buttonText="Close"
                @click="handleModalClose"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import Dialog from '@nswdoe/doe-ui-core/src/Dialog/Dialog.vue';
import AdsButton from '@nswdoe/doe-ui-core/src/AdsButton/AdsButton.vue';
import { mapState, mapGetters } from 'vuex';
import api from '@/api';
import { calculateTotalFileSize } from '@/utils';
import FileCard from '@/components/FileCard.vue';
import { Alert } from '@nswdoe/doe-ui-core';
import { ALLOWED_FILE_EXTENSIONS } from '@/data/constants';

export default {
  name: 'UploadEvidenceModal',
  components: {
    FileCard,
    Dialog,
    AdsButton,
    Alert,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
      default: () => ({}),
    },
    goal: {
      type: Object,
      default: () => ({}),
    },
    observations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ACCEPT_FILE_EXTENSIONS: ALLOWED_FILE_EXTENSIONS.join(', '),
      ADS_Colors,
      localEvidence: [],
      errorMessage: null,
      uploadSuccessful: false,
      totalSize: 0,
      maximum: 10,
      showWarning: false,
    };
  },
  computed: {
    ...mapState({
      pdp: (state) => state.pdp?.pdp ?? undefined,
      totalFileSize: (state) => {
        return state.pdp?.pdp ? calculateTotalFileSize(state.pdp?.pdp) : 0;
      },
    }),
    ...mapGetters(['formPdp']),
    currentGoal() {
      return this.goal;
    },
    currentEvidence() {
      return this.goal.evidenceFiles;
    },
  },
  watch: {
    currentEvidence: {
      handler() {
        this.checkMaximum();
      },
      immediate: true,
    },
  },
  methods: {
    checkMaximum() {
      this.showWarning = this.currentEvidence.length >= this.maximum;
      return this.showWarning;
    },
    setTotalSize() {
      const totalEvidenceSize =
        this.goal.evidenceFiles.reduce((acc, curr) => {
          return acc + curr.size;
        }, 0) || 0;
      const totalObservationSize =
        this.observations.reduce((acc, curr) => {
          return acc + curr.size;
        }, 0) || 0;
      this.totalSize = totalEvidenceSize + totalObservationSize;
    },
    fileSizePrint(size) {
      return (size / 1024 / 1024).toFixed(2);
    },
    handleModalClose() {
      this.$emit('onModalClose');
    },
    async downloadFile(evidenceId, fileName) {
      const evidenceFile = await api.pdp.getEvidenceWithFile(evidenceId);
      if (evidenceFile) {
        const link = document.createElement('a');
        link.href = 'data:application/octet-stream;base64,' + evidenceFile.data;
        link.download = fileName;
        link.click();
      }
    },
    openSelectFile() {
      this.errorMessage = null;
      this.$refs.uploader.click();
    },
    onFileSelection(event) {
      function pathInfo(s) {
        s = s.match(/(.*?\/)?(([^/]*?)(\.[^/.]+?)?)(?:[?#].*)?$/);
        return { path: s[1], file: s[2], name: s[3], ext: s[4] };
      }
      const path = event.target.value.split('\\');
      const fileName = path[path?.length - 1];
      const fileInfo = pathInfo(fileName);
      const fileExtension = fileInfo.ext;
      if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension.toLowerCase())) {
        this.errorMessage = 'Invalid file type';
        return;
      }

      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.addEventListener('load', async (e) => {
        if (e.total > 4194304) {
          this.errorMessage = `File size of ${this.fileSizePrint(
            e.total
          )} MB is too large. Please upload a file less than 4 MB.`;
          return;
        }

        if (this.totalSize + e.total > 20971520) {
          this.errorMessage = `File size of ${this.fileSizePrint(
            e.total
          )} MB will put you over the 20 MB total file limit.`;
          return;
        }
        const evidence = {
          goal_id: this.currentGoal.id,
          title: this.obsTitle,
          fileName: fileName,
          data: e.target.result.split(',')[1],
          size: e.total,
        };

        const pdp = this.formPdp;
        pdp.sections = pdp.sections.map((section) => {
          if (section.id === this.section.id) {
            section.goals = section.goals.map((goal) => {
              if (goal.id === this.goal.id) {
                goal.evidenceFiles.push(evidence);
              }
              return goal;
            });
          }
          return section;
        });
        await this.$store.dispatch('pdp/savePdp', this.formPdp);
        // this.setTotalSize();
      });
    },
  },
};
</script>

<style lang="scss">
@import '../scss/variables';

.error-text {
  color: $ads-error-red;
}

.checkbox-text span {
  color: $ads-dark;
}
.evidence-list {
  overflow-y: auto;
}
</style>

<template>
  <Dialog
    :displayCloseBtn="false"
    :openDialog="openCtsModal"
    :return-handler="handleCtsModalClose"
    :fullscreen="!isMobile"
    maxWidth="55em"
    @close="handleCtsModalClose"
  >
    <template #title>
      <v-row
        v-if="isMobile"
        class="mx-8 pt-10"
      >
        <v-col cols="1">
          <v-btn
            color="#cadaee"
            depressed
            rounded
            :ripple="false"
            :readonly="true"
            min-width="51px"
            max-width="51px"
            dense
          >
            <v-icon
              size="28px"
              :color="'#122a61'"
            >
              mdi-calendar-edit
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="9">
          <p class="headline">Add focus areas</p>
          <p>
            Expand the list below to find desired Standard Descriptors. Select
            <strong>Add selected</strong>
            to add them to your PDP.
          </p>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="1">
          <v-btn
            icon
            :ripple="false"
            :readonly="true"
            dense
            @click="handleCtsModalClose"
            aria-label="close"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-container
        v-else
        class="pa-0"
      >
        <v-row class="ma-0">
          <v-toolbar
            color="primary"
            elevation="0"
          >
            <v-btn
              icon
              @click="handleCtsModalClose"
              aria-label="close"
            >
              <v-icon color="white">close</v-icon>
            </v-btn>
            <v-toolbar-title class="font-weight-bold">
              Add focus areas
            </v-toolbar-title>
          </v-toolbar>
        </v-row>
        <v-row class="mx-5 mt-6">
          <p>
            Expand the list below to find desired Standard Descriptors. Select
            <strong>Add selected</strong>
            to add them to your PDP.
          </p>
        </v-row>
      </v-container>
    </template>
    <template #text>
      <v-container
        class="ma-0 px-5 px-md-7"
        data-test="descriptors-modal"
      >
        <v-row
          v-if="showWarning"
          class="ma-0 ma-md-n3"
        >
          <v-col class="pa-0 pa-md-3 pt-md-0">
            <Alert
              type="warning"
              text="You have added the maximum number of standard descriptors to your goal."
              subtext="Please work with your PDP supervisor to refine your goals."
              inPage
              :backgroundColour="true"
              :border="isMobile ? 'left' : 'top'"
              class="ma-0 ma-md-4"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col
            cols="auto"
            class="ma-0 ml-md-4 pa-0"
          >
            <p class="subtitle-1 mb-1">Focus areas added:</p>
            <v-chip-group
              column
              class="ma-0 pa-0"
            >
              <v-chip
                v-for="standard in activeStandards"
                :key="`selected-standard-${currentGoal.id}${standard.standardIndex}${standard.focusIndex}${standard.levelIndex}`"
                close
                class="ml-1 list-item"
                style="background: #d0ebfb"
                textColor="primary"
                @click="
                  jumpTo(
                    currentGoal.id,
                    standard.standardIndex,
                    standard.focusIndex,
                    standard.levelIndex
                  )
                "
                @click:close="
                  deleteStandard(
                    standard.standardIndex,
                    standard.focusIndex,
                    standard.levelIndex
                  )
                "
              >
                {{
                  `${standard.standardIndex}.${standard.focusIndex}.${
                    standard.levelIndex
                  } ${getFirstNSubstrings(standard.level, 2)}`
                }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col
            cols="auto"
            class="ma-0 pa-0"
          >
            <v-list expand>
              <v-list-group
                v-for="(standard, standardIndex) in allStandards"
                :key="`standard-${standard.index}-goal-${currentGoal.id}`"
                :value="isOpenStandard[standardIndex]"
                class="ma-0 pa-0"
              >
                <template #activator>
                  <v-list-item-content>
                    <p
                      class="pa-0 ma-0 font-size-18"
                      style="line-height: 1.5rem"
                      v-dompurify-html:sanitize="
                        `<strong>${standard.index}.</strong>
                      ${standard.title}`
                      "
                    />
                  </v-list-item-content>
                </template>
                <v-list-group
                  v-for="(focus, focusIndex) in standard.focus"
                  :key="`standard-${currentGoal.id}${standard.index}-focus-${focus.index}`"
                  :value="isOpenFocus[standardIndex][focusIndex] ?? false"
                  no-action
                  sub-group
                  class="ma-0 pa-0"
                >
                  <template #activator>
                    <v-list-item-content>
                      <p
                        class="pa-0 ma-0 font-size-18"
                        style="line-height: 1.5rem"
                        v-dompurify-html:sanitize="
                          `<strong>${standard.index}.${focus.index}</strong>
                        ${focus.title}`
                        "
                      />
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="level in focus.level"
                    :key="`level-${currentGoal.id}${standard.index}.${focus.index}.${level.index}`"
                  >
                    <v-card
                      outlined
                      :id="`${currentGoal.id}.${standard.index}.${focus.index}.${level.index}`"
                      :class="`${
                        isStandardSelected(
                          standard.index,
                          focus.index,
                          level.index
                        )
                          ? 'selected'
                          : ''
                      } my-2 list-item`"
                      min-width="100%"
                      :ripple="false"
                      @click.stop="
                        handleStandardSelect(
                          standard.index,
                          focus.index,
                          level.index
                        )
                      "
                    >
                      <v-card-text>
                        <v-row class="align-start align-md-center">
                          <v-col
                            class="flex-shrink-1 flex-grow-0 justify-center align-start align-md-center"
                          >
                            <v-checkbox
                              :input-value="
                                isStandardSelected(
                                  standard.index,
                                  focus.index,
                                  level.index
                                )
                              "
                              :disabled="
                                !isStandardSelected(
                                  standard.index,
                                  focus.index,
                                  level.index
                                ) && activeStandards.length === maximum
                              "
                            />
                          </v-col>
                          <v-col class="flex-shrink-1 flex-grow-0">
                            <p
                              class="pa-0 ma-0 font-size-16 font-weight-bold ads-grey-01"
                              style="line-height: 1.5rem"
                              v-dompurify-html:sanitize="
                                `${standard.index}.${focus.index}.${level.index}`
                              "
                            />
                          </v-col>
                          <v-col class="flex-grow-1">
                            <p
                              class="text-md-body-2 correct-color font-size-16 line-height-24 mb-0"
                              v-dompurify-html:plaintext="level.title"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-list-item>
                </v-list-group>
              </v-list-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
      <v-row class="bottom-row">
        <v-col class="button-right">
          <v-btn
            color="primary"
            data-test="add-descriptor"
            @click="handleFinish"
          >
            Add selected
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import { mdiChevronRight } from '@mdi/js';
import { mapGetters } from 'vuex';
import standardsData, { maxStandards } from '../data/standards';
import { clone } from 'ramda';
import { Alert, Dialog } from '@nswdoe/doe-ui-core';

export default {
  name: 'CreateTeachingStandard',
  props: {
    openCtsModal: Boolean,
    section: { type: Object, default: null },
    goal: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Dialog,
    Alert,
  },
  data() {
    return {
      icons: {
        mdiChevronRight,
      },
      forceExpand: {
        standard: null,
        focus: null,
        level: null,
      },
      activeStandards: [],
      openSet: { standard: new Set(), focus: new Set() },
      allStandards: standardsData,
      isOpenStandard: [],
      isOpenFocus: standardsData.map((item) => item.focus.map(() => false)),
      maximum: maxStandards,
      showWarning: false,
    };
  },
  computed: {
    ...mapGetters(['formPdp']),
    currentGoal() {
      return clone(this.goal);
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  watch: {
    openCtsModal: {
      handler() {
        if (this.openCtsModal) {
          this.updateActive();
          this.updateCompleteStandardOpenSet();
        } else {
          this.isOpenStandard = [];
          this.isOpenFocus = standardsData.map((item) =>
            item.focus.map(() => false)
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateActive() {
      this.activeStandards = this.currentGoal.standards?.filter((item) => {
        return item.markForDelete === false || item.markForDelete === undefined;
      });
    },
    jumpTo(goalId, standard, focus, level) {
      this.isOpenStandard.splice(standard - 1, 1, true);
      this.isOpenFocus[standard - 1].splice(focus - 1, 1, true);
      setTimeout(() => {
        const el = document.getElementById(
          `${goalId}.${standard}.${focus}.${level}`
        );
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    },
    async handleFinish() {
      this.formPdp.sections = this.formPdp.sections.map((section) => {
        if (section.id === this.section.id) {
          section.goals = section.goals.map((goal) => {
            if (goal.id === this.currentGoal.id) return this.currentGoal;
            return goal;
          });
        }
        return section;
      });
      this.handleCtsModalClose();
    },
    handleCtsModalClose() {
      this.$emit('closeCtsModal');
    },
    isStandardSelected(standardIndex, focusIndex, levelIndex) {
      let selected = false;
      let result = undefined;
      if (this.currentGoal.standards?.length > 0) {
        result = this.currentGoal.standards?.find(
          (standard) =>
            standard.standardIndex === standardIndex &&
            standard.focusIndex === focusIndex &&
            standard.levelIndex === levelIndex &&
            !standard.markForDelete
        );
      }
      selected = !!result;
      return selected;
    },
    updateOpenSet(standardIndex, focusIndex) {
      this.openSet.standard.add(standardIndex);
      this.openSet.focus.add(Number(`${standardIndex}.${focusIndex}`));
    },
    updateCompleteStandardOpenSet() {
      if (this.activeStandards.length) {
        for (const standard of this.activeStandards) {
          this.updateOpenSet(standard.standardIndex, standard.focusIndex);
        }
      }
      if (this.openSet.standard.size) {
        this.isOpenStandard = this.allStandards.map((item, index) => {
          const isOpenStandard = this.checkIsOpen(item.index);
          this.isOpenFocus[index] = item.focus.map((focusItem) => {
            return this.checkIsOpen(item.index, focusItem.index);
          });
          return isOpenStandard;
        });
      }
    },
    checkIsOpen(standard, focus) {
      if (standard && focus) {
        return this.openSet.focus.has(Number(`${standard}.${focus}`));
      } else if (standard && !focus) {
        return this.openSet.standard.has(standard);
      }
    },
    handleStandardSelect(standardIndex, focusIndex, levelIndex) {
      const isMaximum = this.checkMaximum();
      let existing = undefined;
      const standard = this.currentGoal.standards?.find((item, index) => {
        if (
          item.standardIndex === standardIndex &&
          item.focusIndex === focusIndex &&
          item.levelIndex === levelIndex
        ) {
          existing = index;
          return true;
        } else return false;
      });
      if (standard) {
        if (
          standard.markForDelete === undefined ||
          standard.markForDelete === false
        ) {
          standard.markForDelete = true;
          this.currentGoal.standards.splice(existing, 1);
          this.currentGoal.standards.push(standard);
        } else if (standard.markForDelete === true && !isMaximum) {
          standard.markForDelete = false;
          this.currentGoal.standards.splice(existing, 1);
          this.currentGoal.standards.push(standard);
        }
        this.updateActive();
      } else if (!isMaximum) {
        const foundStandard = this.allStandards.find(
          (standard) => standard.index === standardIndex
        );
        if (foundStandard) {
          const foundFocus = foundStandard.focus.find(
            (focus) => focus.index === focusIndex
          );
          if (foundFocus) {
            const foundLevel = foundFocus.level.find(
              (level) => level.index === levelIndex
            );
            if (foundLevel) {
              const newStandard = {
                goal_id: this.currentGoal.id,
                standard: foundStandard.title,
                standardIndex: standardIndex,
                focus: foundFocus.title,
                focusIndex: focusIndex,
                level: foundLevel.title,
                levelIndex: levelIndex,
              };
              this.currentGoal.standards.push(newStandard);
              this.updateActive();
            }
          }
        }
      }
    },
    checkMaximum() {
      const existingStandardsLength = this.currentGoal?.standards?.filter(
        (item) => !item.markForDelete
      ).length;
      this.showWarning = existingStandardsLength >= this.maximum;
      return this.showWarning;
    },
    deleteStandard(standardIndex, focusIndex, levelIndex) {
      let existing = undefined;
      const standard = this.currentGoal?.standards.find((item, index) => {
        if (
          item.standardIndex === standardIndex &&
          item.focusIndex === focusIndex &&
          item.levelIndex === levelIndex
        ) {
          existing = index;
          return true;
        } else return false;
      });
      if (standard && standard.id) {
        this.currentGoal.standards[existing].markForDelete = true;
        this.updateActive();
      } else if (standard && !standard.id) {
        this.currentGoal.standards.splice(existing, 1);
        this.updateActive();
      }
    },
    getFirstNSubstrings(str, nOfSubstrings) {
      if (str.trim().length === 0) return str;

      const substrings = str.trim().split(' ');
      if (substrings.length <= nOfSubstrings) return str;

      const firstNSubstrings = substrings.slice(0, nOfSubstrings);
      return firstNSubstrings.join(' ') + '...';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.correct-color {
  color: #000;
}

.full-width {
  width: 100%;
  text-align: left;
  justify-content: left;
}

.button-right {
  float: right;
}

.dark-border {
  border-color: $ads-dark-80;
}

.selected-border {
  border-color: $ads-success-green;
  border-width: 3px;
}

.card-outer {
  position: relative;
  padding-bottom: 50px;
}

.bottom-row {
  position: sticky;
  bottom: 0;
  justify-content: flex-end;
  margin: 0;
  background-color: $ads-white;
  border-top: solid 1px $ads-light-20;

  .button-right {
    flex-grow: 0;
  }
}

.selected {
  background: #d0ebfb;
}

.list-item {
  outline: 1px solid black;
}

:deep .v-toolbar__content .v-toolbar__title {
  color: $ads-white;
}

:deep .v-list-group .v-list-group {
  .v-input--selection-controls {
    &__input {
      margin: 0;
    }
  }
}

@media screen and (max-width: 959px) {
  :deep .v-list-group .v-list-group {
    &__header.v-list-item {
      padding-left: 0;
      padding-right: 0;
    }
    &__items > .v-list-item {
      padding-left: 1px !important;
      padding-right: 1px !important;
    }
    .v-input--checkbox {
      margin: 0;
      padding: 0;
    }
    .v-input--selection-controls {
      &__input {
        margin: 0;
      }
      &__ripple:before {
        display: none;
      }
    }
  }
  :deep .v-alert {
    &__border--top {
      border-width: 3px !important;
    }
  }
  .line-height-24 {
    line-height: 24px;
  }
  .font-size-18 {
    font-size: 18px;
  }
  .font-size-16 {
    font-size: 16px;
  }
}
</style>

import { FormValidationState, Goal } from '../types';

export default {
  namespaced: true,
  state: {
    goals: [],
    pdpSupervisor: {},
  } as FormValidationState,
  getters: {
    allGoalsValid(state: FormValidationState) {
      if (state.goals.length > 0) {
        for (const goal of state.goals) {
          if (!goal.isValid) return false;
        }
        return true;
      } else {
        return false;
      }
    },
    validationGoals(state: FormValidationState) {
      return state.goals;
    },
  },
  mutations: {
    addGoal(state: FormValidationState, newGoal: Goal) {
      const isNewGoal = !state.goals.find((storedGoal) => {
        return storedGoal.id === newGoal.id;
      });
      if (isNewGoal) {
        state.goals = [...state.goals, newGoal];
      }
    },
    updateGoalValidity(state: FormValidationState, goal: Goal) {
      const existingGoal = state.goals.find((storedGoal) => {
        return storedGoal.id === goal.id;
      });
      if (existingGoal) {
        existingGoal.isValid = goal.isValid;
      }
    },
    resetGoalsValidity(state: FormValidationState) {
      state.goals.length = 0;
    },
    deleteGoal(state: FormValidationState, goalToRemove: Goal) {
      state.goals = state.goals.filter((goal) => {
        return goal.id !== goalToRemove.id;
      });
    },
  },
};

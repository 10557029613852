<template>
  <div>
    <!-- Expired PDP Dialog -->
    <Dialog
      :displayCloseBtn="false"
      :openDialog="showExpiredPdpDialog"
      maxWidth="60%"
    >
      <template #text>
        <PdpUpdateDialog
          class="ma-0 px-7"
          :openDialog="showExpiredPdpDialog"
          @closeExpiredPdpDialog="closeExpiredPdpDialog"
        />
      </template>
    </Dialog>
    <!-- ... Expired PDP Dialog -->
    <div>
      <v-app-bar
        :color="ADS_Colors.PRIMARY_BLUE"
        elevation="0"
      >
        <AdsButton
          :buttonText="
            actionPdp?.owner
              ? actionPdp?.owner?.staffPreferredName
                ? `${actionPdp?.owner?.staffPreferredName} PDP`
                : `${actionPdp?.owner?.staffGivenName} ${actionPdp?.owner?.staffFamilyName} PDP`
              : ''
          "
          :ripple="false"
          class="ma-0 pa-2"
          icon="mdi-close"
          left
          style="
            border-style: none !important;
            background-color: rgba(0, 0, 0, 0) !important;
            color: white !important;
          "
          tertiary
          @click.stop="handleBackButton"
        />
      </v-app-bar>
      <UserBanner
        :user="actionPdp?.owner"
        :pdp="actionPdp"
      />
      <Ribbon
        :pdp="actionPdp"
        :planSection="planSection"
        @selected="pdpSelected"
        @print="getNotes"
      >
        <template #printContent>
          <TSSupervisorPrintContent
            v-if="actionPdp?.active"
            :actionPdp="actionPdp"
            :planSection="planSection"
            :midYearSection="midYearSection"
            :finalSection="finalSection"
            :midYearSupervisor="midYearSupervisor"
            :finalSupervisor="finalSupervisor"
            :activeSection="activeSection"
            :isCurrentSupervisor="isCurrentSupervisor"
            :isPlanSupervisor="isPlanSupervisor"
            :isMidYearSupervisor="isMidYearSupervisor"
            :supervisorName="supervisorName"
            :showTabs="showTabs"
            :selectedTab="selectedTab"
            :owner="actionPdp?.owner"
            :userIsPrincipal="userIsSchoolPrincipal"
            :isSigningPrintBanner="isSigningBanner"
            :supervisorNotes="notes"
          />
          <TSSupervisorArchivedPrintContent
            v-else
            :actionPdp="actionPdp"
            :planSection="planSection"
            :midYearSection="midYearSection"
            :finalSection="finalSection"
            :midYearSupervisor="midYearSupervisor"
            :finalSupervisor="finalSupervisor"
            :isCurrentSupervisor="isCurrentSupervisor"
            :isPlanSupervisor="isPlanSupervisor"
            :isMidYearSupervisor="isMidYearSupervisor"
            :selectedTab="selectedTab"
            :owner="actionPdp?.owner"
            :userIsPrincipal="userIsSchoolPrincipal"
            :isSupervisor="true"
            :supervisorNotes="notes"
          />
        </template>
      </Ribbon>
      <div v-if="!showTabs">
        <v-container class="px-0 px-md-8">
          <div
            v-if="
              !isCurrentSupervisor &&
              !isPlanSupervisor &&
              !userIsSchoolPrincipal
            "
          >
            <NoPermissionCard />
          </div>
          <div v-else>
            <OrientationBanner
              v-if="isCurrentSupervisor"
              :isSupervisor="true"
              :pdp="actionPdp ?? {}"
              :section="activeSection"
              :supervisorName="supervisorName"
              class="pa-0 mt-3"
              :isSigningPrintBanner="isSigningBanner"
              @startSigning="isSigningBanner = true"
              @signed="handleSignedForm"
            />
            <TSPlanReadOnly
              :pdp="actionPdp"
              :section="planSection"
            />
          </div>
        </v-container>
      </div>
      <div v-if="showTabs">
        <v-tabs
          v-model="tab"
          class="ma-0 pa-0"
          slider-color="red"
        >
          <v-tab
            v-for="(item, index) in availableTabs"
            :key="`managementtab${index + 1}`"
          >
            {{ item.tab }}
          </v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item key="tabItem1">
              <v-container
                v-if="
                  !isCurrentSupervisor &&
                  !isPlanSupervisor &&
                  !userIsSchoolPrincipal
                "
              >
                <NoPermissionCard />
              </v-container>
              <TSPlanReadOnly
                v-else
                :pdp="actionPdp"
                :section="planSection"
              />
            </v-tab-item>
            <v-tab-item key="tabItem2">
              <v-container class="pa-0 pa-md-3">
                <NoPermissionCard
                  v-if="
                    !isCurrentSupervisor &&
                    !isMidYearSupervisor &&
                    !userIsSchoolPrincipal
                  "
                />
                <div v-else>
                  <OrientationBanner
                    v-if="
                      showOrientationBannerOnGoalsAndEvidence &&
                      isCurrentSupervisor &&
                      !isActionPdpArchived
                    "
                    :isSupervisor="true"
                    :pdp="actionPdp ?? {}"
                    :section="activeSection"
                    :supervisorName="supervisorName"
                    class="pa-0 mt-3"
                    :isSigningPrintBanner="isSigningBanner"
                    @startSigning="isSigningBanner = true"
                    @signed="handleSignedForm"
                  />
                  <v-card class="my-5">
                    <IncompleteFieldCard
                      class="mt-8 py-5 px-0"
                      v-if="
                        isActionPdpIncompleteArchived &&
                        !midYearSection?.goals?.length
                      "
                    />
                    <v-container
                      class="px-0 px-md-10 py-5"
                      fluid
                      v-else
                    >
                      <TSPdpGoals
                        :editable="false"
                        :isSupervisor="true"
                        :owner="actionPdp?.owner"
                        :section="midYearSection"
                        :showEditToggle="false"
                        :startEdit="false"
                        :userIsPrincipal="ownerIsPrincipal"
                      />
                    </v-container>
                  </v-card>
                </div>
              </v-container>
            </v-tab-item>
            <v-tab-item key="tabItem3">
              <v-container
                v-if="
                  !isCurrentSupervisor &&
                  !isMidYearSupervisor &&
                  !userIsSchoolPrincipal
                "
              >
                <NoPermissionCard />
              </v-container>
              <div v-else>
                <v-container
                  class="px-0 px-md-3 mt-0 pt-0"
                  v-if="
                    isActionPdpIncompleteArchived &&
                    !actionPdp?.observations?.length
                  "
                >
                  <IncompleteFieldCard class="mt-8 py-5 px-0" />
                </v-container>
                <Wrapper
                  v-else
                  class="pt-5 px-3"
                  cardClass="py-5 px-10 mt-5"
                  :isMobile="isMobile"
                >
                  <TSPdpObservations
                    :edit-mode="false"
                    :pdp="actionPdp"
                  />
                </Wrapper>
                <v-container
                  class="px-0 px-md-3 mt-0 pt-0"
                  v-if="isActionPdpIncompleteArchived"
                ></v-container>
              </div>
            </v-tab-item>
            <v-tab-item key="tabItem4">
              <v-container class="px-0 px-md-8">
                <NoPermissionCard
                  v-if="
                    !isCurrentSupervisor &&
                    !isMidYearSupervisor &&
                    !userIsSchoolPrincipal
                  "
                />
                <div v-else>
                  <OrientationBanner
                    v-if="
                      !showOrientationBannerOnGoalsAndEvidence &&
                      (isCurrentSupervisor || finalSection.complete) &&
                      !isActionPdpArchived
                    "
                    :isSupervisor="true"
                    :pdp="actionPdp ?? {}"
                    :section="activeSection"
                    :supervisorName="supervisorName"
                    class="pa-0 mt-3"
                    :isSigningPrintBanner="isSigningBanner"
                    @startSigning="isSigningBanner = true"
                    @signed="handleSignedForm"
                  />
                  <Wrapper
                    :isMobile="isMobile"
                    cardClass="py-5 px-10"
                    class="mt-8 px-0"
                  >
                    <h4 class="display-1 pt-5 mb-5 mx-5 mx-md-0">Reviews</h4>
                    <AdsExpansionPanel
                      v-model="reviewPanels"
                      :items="[
                        { id: 1, title: 'Mid-Year Self-Assessment' },
                        ...(isCurrentSupervisor ||
                        (!isCurrentSupervisor &&
                          !isMidYearSupervisor &&
                          userIsSchoolPrincipal) ||
                        (!isCurrentSupervisor && isMidYearSupervisor)
                          ? [{ id: 2, title: 'Annual review' }]
                          : []),
                      ]"
                      multiple
                    >
                      <template #[`content1`]>
                        <v-container class="pa-0 pa-md-5">
                          <v-card class="pa-5">
                            <v-card-title class="px-0 px-md-4 text-h6">
                              Review of progress
                            </v-card-title>
                            <div class="px-0 px-md-4">
                              <p
                                class="text-body-1"
                                v-if="actionPdp.midYearText?.length"
                                v-dompurify-html="
                                  actionPdp.midYearText
                                    ? actionPdp.midYearText
                                    : ''
                                "
                              />
                              <IncompleteFieldCard
                                v-else-if="isActionPdpIncompleteArchived"
                              />
                            </div>
                            <p class="title mt-5">
                              <v-icon
                                :color="ADS_Colors.Blue_2"
                                class="mr-2"
                              >
                                mdi-message-reply-text-outline
                              </v-icon>
                              Comments
                            </p>
                            <v-row class="mx-n5 mx-md-n3 flex-column">
                              <v-col class="px-0 px-md-3">
                                <v-card
                                  v-if="midYearSection?.supervisorSignature"
                                  class="elevation-4 mx-0 mx-md-5 mt-2"
                                >
                                  <p
                                    class="px-5 pt-5 px-md-4 text-body-1"
                                    v-dompurify-html="
                                      midYearSection.supervisorComment
                                        ? midYearSection.supervisorComment
                                        : 'No comments from PDP supervisor'
                                    "
                                  />
                                  <v-card-title
                                    v-if="midYearSection?.supervisorSignature"
                                    class="px-5 px-md-4 text-h6"
                                  >
                                    PDP Supervisor
                                    {{ formatName(midYearSupervisor) }}
                                    signed this section on
                                    {{
                                      formatDateTime(
                                        midYearSection?.supervisorSignTime
                                      )
                                    }}
                                  </v-card-title>
                                </v-card>
                                <IncompleteFieldCard
                                  class="mx-0 mx-md-5 mt-2"
                                  v-else-if="isActionPdpIncompleteArchived"
                                />
                              </v-col>
                              <v-col class="px-0 px-md-3">
                                <v-card
                                  v-if="midYearSection?.userSignature"
                                  class="elevation-4 mx-0 mx-md-5 mt-2"
                                >
                                  <p
                                    class="px-5 pt-5 px-md-4 text-body-1"
                                    v-dompurify-html="
                                      midYearSection?.userComment
                                        ? midYearSection?.userComment
                                        : 'No comments from supervisee'
                                    "
                                  />
                                  <v-card-title
                                    v-if="midYearSection?.userSignTime"
                                    class="px-5 px-md-4 text-h6"
                                  >
                                    {{ formatName(actionPdp?.owner) }}
                                    signed this section on
                                    {{
                                      formatDateTime(
                                        midYearSection?.userSignTime
                                      )
                                    }}
                                  </v-card-title>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-container>
                        <v-card
                          v-if="!midYearSection?.complete"
                          class="pa-5"
                          flat
                        >
                          <p class="ml-0 ml-md-5">
                            <strong>Mid Year Self-Assessment</strong>
                            has not yet been completed
                          </p>
                        </v-card>
                      </template>
                      <template #[`content2`]>
                        <v-container
                          v-if="!isCurrentSupervisor"
                          class="pa-0 pa-md-5"
                        >
                          <NoPermissionCard />
                        </v-container>
                        <v-container
                          v-else-if="
                            isCurrentSupervisor || userIsSchoolPrincipal
                          "
                          class="pa-0 pa-md-5"
                        >
                          <v-card class="pa-5">
                            <v-card-title class="px-0 px-md-4 text-h6">
                              Annual review
                            </v-card-title>
                            <div class="px-0 px-md-4">
                              <p
                                class="text-body-1"
                                v-if="actionPdp.annualText?.length"
                                v-dompurify-html="
                                  actionPdp?.annualText
                                    ? actionPdp?.annualText
                                    : ''
                                "
                              />
                              <IncompleteFieldCard
                                v-else-if="isActionPdpIncompleteArchived"
                              />
                            </div>
                            <p class="title mt-5">
                              <v-icon
                                :color="ADS_Colors.Blue_2"
                                class="mr-2"
                              >
                                mdi-message-reply-text-outline
                              </v-icon>
                              Comments
                            </p>
                            <v-row class="mx-n5 mx-md-n3 flex-column">
                              <v-col class="px-0 px-md-3">
                                <v-card
                                  v-if="finalSection?.supervisorSignature"
                                  class="elevation-4 mx-0 mx-md-5 mt-2"
                                >
                                  <p
                                    class="px-5 pt-5 px-md-4 text-body-1"
                                    v-dompurify-html="
                                      finalSection?.supervisorComment
                                        ? finalSection?.supervisorComment
                                        : 'No comments from PDP supervisor'
                                    "
                                  />
                                  <v-card-title
                                    v-if="finalSection?.supervisorSignTime"
                                    class="px-5 px-md-4 text-h6"
                                  >
                                    PDP Supervisor
                                    {{ formatName(finalSupervisor) }}
                                    signed this section on
                                    {{
                                      formatDateTime(
                                        finalSection?.supervisorSignTime
                                      )
                                    }}
                                  </v-card-title>
                                </v-card>
                                <IncompleteFieldCard
                                  class="mx-0 mx-md-5 mt-2"
                                  v-else-if="isActionPdpIncompleteArchived"
                                />
                              </v-col>
                              <v-col class="px-0 px-md-3">
                                <v-card
                                  v-if="finalSection?.userSignature"
                                  class="elevation-4 mx-0 mx-md-5 mt-2"
                                >
                                  <p
                                    class="px-5 pt-5 px-md-4 text-body-1"
                                    v-dompurify-html="
                                      finalSection?.userComment
                                        ? finalSection?.userComment
                                        : 'No comments from supervisee'
                                    "
                                  />
                                  <v-card-title
                                    v-if="finalSection?.userSignTime"
                                    class="px-5 px-md-4 text-h6"
                                  >
                                    {{ formatName(actionPdp?.owner) }}
                                    signed and archived this section on
                                    {{
                                      formatDateTime(finalSection?.userSignTime)
                                    }}
                                  </v-card-title>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-container>
                        <v-card
                          v-if="!finalSection?.complete && isCurrentSupervisor"
                          class="pa-5"
                          flat
                        >
                          <p class="ml-0 ml-md-5">
                            <strong>Annual review</strong>
                            has not yet been completed
                          </p>
                        </v-card>
                      </template>
                    </AdsExpansionPanel>
                  </Wrapper>
                  <IncompleteArchiveCard
                    v-if="
                      isCurrentSupervisor &&
                      isArchivedDuringReview &&
                      isActionPdpIncompleteArchived
                    "
                    :pdp="actionPdp"
                  />
                </div>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>
    </div>
    <portal
      v-if="isCurrentSupervisor || isPlanSupervisor || isMidYearSupervisor"
      selector=".v-application--wrap"
    >
      <div class="notes-wrapper">
        <NotesBar
          v-model="notes"
          :open="notesOpen"
          :edit="actionPdp?.active && pdpUser?.active"
          :archived="!actionPdp.active"
          @openNotes="openNotes"
          @closeNotes="closeNotes"
          @updateNotes="updateNotes"
        />
      </div>
    </portal>
  </div>
</template>

<script>
import TSPdpGoals from '@/components/TSPdpGoals';
import UserBanner from '@/components/UserBanner';
import OrientationBanner from '@/components/OrientationBanner';
import Ribbon from '@/components/Ribbon';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { mapActions, mapGetters } from 'vuex';
import TSPlanReadOnly from '@/components/TSPlanReadOnly';
import {
  AdsButton,
  AdsExpansionPanel,
  Dialog,
} from '@nswdoe/doe-ui-core/index';
import Wrapper from '@/components/Wrapper';
import api from '@/api';
import TSPdpObservations from '@/components/TSPdpObservations';
import NotesBar from '@/components/NotesBar';
import { formatDateTime, formatName } from '@/utils';
import { clone } from 'ramda';
import { SECTION_TYPE, TAB_TYPES, USER_TYPE } from '@/data/constants';
import TSSupervisorPrintContent from '@/components/print/TSSupervisorPrintContent';
import TSSupervisorArchivedPrintContent from '@/components/print/TSSupervisorArchivedPrintContent';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard.vue';
import IncompleteArchiveCard from '@/components/IncompleteArchiveCard.vue';
import NoPermissionCard from '@/components/print/NoPermissionCard.vue';
import {
  findPdpUserByType,
  findPdpUserByUserId,
  getPdpSectionOnArchival,
} from '@/lib/pdp';
import PdpUpdateDialog from '@/components/PdpUpdateDialog';
import { Portal } from '@linusborg/vue-simple-portal';

export default {
  name: 'TSActionPdp',
  props: {
    selectedTab: {
      type: String,
      default: '',
    },
    actionTab: {
      type: String,
      default: '',
    },
    showExpiredPdpDialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NoPermissionCard,
    IncompleteArchiveCard,
    IncompleteFieldCard,
    TSPdpGoals,
    TSPdpObservations,
    OrientationBanner,
    TSPlanReadOnly,
    TSSupervisorPrintContent,
    Ribbon,
    AdsExpansionPanel,
    AdsButton,
    NotesBar,
    Wrapper,
    UserBanner,
    TSSupervisorArchivedPrintContent,
    Dialog,
    PdpUpdateDialog,
    Portal,
  },
  data() {
    return {
      ADS_Colors,
      TAB_TYPES,
      USER_TYPE,
      tab: undefined,
      reviewPanels: [0, 1],
      availableTabs: [
        TAB_TYPES.plan,
        TAB_TYPES.goalsAndEvidence,
        TAB_TYPES.observations,
        TAB_TYPES.reviews,
      ],
      isSigningBanner: false,
      notesOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'loggedInUser',
      'actionPdp',
      'isActionPdpArchived',
      'isActionPdpIncompleteArchived',
    ]),
    ...mapGetters('pdp', {
      planSection: 'actionPdpPlanSection',
      midYearSection: 'actionPdpMidYearSection',
      finalSection: 'actionPdpFinalSection',
      actionPdpUser: 'actionPdpUser',
      personalNotes: 'personalNotes',
    }),
    latestSupervisor() {
      return findPdpUserByType(
        this.actionPdp?.pdp_user_associations,
        USER_TYPE.supervisor
      );
    },
    pdpUser() {
      return findPdpUserByUserId(
        this.actionPdp?.pdp_user_associations,
        this.loggedInUser.id,
        USER_TYPE.supervisor
      );
    },
    notes: {
      get() {
        return this.personalNotes?.text;
      },
      set(value) {
        this.personalNotes.text = value;
      },
    },
    supervisorName() {
      return formatName(this.latestSupervisor?.user);
    },
    activeSection() {
      if (!this.planSection?.complete) {
        return this.planSection;
      }
      if (!this.midYearSection?.complete) {
        return this.midYearSection;
      }
      if (!this.finalSection?.complete) {
        return this.finalSection;
      }
      return undefined;
    },
    isLastActiveSection() {
      return this.activeSection && !this.actionPdp.active;
    },
    pdpSectionOnArchival() {
      return getPdpSectionOnArchival(this.actionPdp);
    },
    isArchivedDuringReview() {
      return (
        this.pdpSectionOnArchival?.id === this.midYearSection?.id ||
        this.pdpSectionOnArchival?.id === this.finalSection?.id
      );
    },
    showTabs() {
      return (
        this.planSection?.complete ||
        this.isActionPdpArchived ||
        ((this.isPlanSupervisor || this.isMidYearSupervisor) &&
          !this.isCurrentSupervisor)
      );
    },
    isCurrentSupervisor() {
      return this.latestSupervisor?.user_id === this.loggedInUser?.id;
    },
    isPlanSupervisor() {
      return this.planSection?.supervisor_id === this.loggedInUser?.id;
    },
    isMidYearSupervisor() {
      return this.midYearSection?.supervisor_id === this.loggedInUser?.id;
    },
    midYearSupervisor() {
      return this.actionPdpUser(SECTION_TYPE.midYear, USER_TYPE.supervisor);
    },
    finalSupervisor() {
      return this.actionPdpUser(SECTION_TYPE.final, USER_TYPE.supervisor);
    },
    showOrientationBannerOnGoalsAndEvidence() {
      return !this.planSection?.complete;
    },
    userIsSchoolPrincipal() {
      return (
        (this.selectedTab === TAB_TYPES.school.id ||
          this.selectedTab === TAB_TYPES.archived.id) &&
        this.loggedInUser?.isPrincipal &&
        this.loggedInUser?.schoolCode ===
          this.actionPdp?.owner_school?.schoolCode
      );
    },
    ownerIsPrincipal() {
      return this.actionPdp?.owner?.isPrincipal;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    actionTab: {
      async handler() {
        if (this.actionTab) {
          await this.pushToCorrectRoute();
        }
      },
      immediate: true,
    },
    tab: {
      async handler() {
        if (this.availableTabs && this.showTabs && !this.actionTab) {
          await this.syncRouteToTab();
        }
      },
    },
  },
  methods: {
    formatName,
    ...mapActions('pdp', [
      'getActionPdp',
      'saveActionPdp',
      'getPersonalNotes',
      'updatePersonalNotes',
    ]),
    pdpSelected(id) {
      this.$emit('selected', id);
    },
    async syncTabToRoute() {
      this.tab = this.availableTabs.findIndex(
        (item) => item.id === this.actionTab
      );
    },
    async syncRouteToTab() {
      const tab = this.availableTabs.findIndex(
        (item) => item.id === this.actionTab
      );
      if (this.tab !== tab) {
        await this.$router.push(
          `/management/${this.selectedTab}/${this.actionPdp.id}/${
            this.availableTabs[this.tab].id
          }`
        );
      }
    },
    async pushToCorrectRoute() {
      if (this.showTabs && !this.actionTab && this.availableTabs) {
        const reviewRoute = `/management/${this.selectedTab}/${
          this.actionPdp.id
        }/${
          this.availableTabs[
            this.availableTabs.findIndex(
              (item) => item.id === TAB_TYPES.reviews.id
            )
          ].id
        }`;
        if (
          (this.isCurrentSupervisor || this.isMidYearSupervisor) &&
          this.planSection?.complete &&
          (this.midYearSection?.notifySupervisor ||
            this.midYearSection?.supervisorSignature) &&
          this.$route.path !== reviewRoute
        ) {
          await this.$router.push(reviewRoute);
        }
      } else if (
        this.availableTabs &&
        this.selectedTab &&
        this.actionTab &&
        this.showTabs
      ) {
        await this.syncTabToRoute();
      } else if (this.availableTabs && this.selectedTab && this.showTabs) {
        await this.syncTabToRoute();
      }
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    handleBackButton() {
      this.$router.push(`/management/${this.selectedTab}`);
    },
    async loadObservationFiles(pdp) {
      let observations = [];
      if (pdp) {
        for (const item of pdp.observations) {
          const observation = await api.pdp.getObservationWithFile(item.id);
          observations.push(observation);
        }
      }
      return observations;
    },
    async downloadFile(observationId, fileName) {
      const observation = this.actionPdp.observations.find(
        (o) => o.id === observationId
      );

      if (observation) {
        const link = document.createElement('a');
        link.href = 'data:application/octet-stream;base64,' + observation.data;
        link.download = fileName;
        link.click();
      }
    },
    async handleSignedForm(sectionToBeUpdated, comment) {
      if (sectionToBeUpdated) {
        let pdp = clone(this.actionPdp);
        pdp.sections = pdp?.sections.map((s) => {
          if (s.sectionType === sectionToBeUpdated.sectionType) {
            s.supervisor_id = this.loggedInUser.id;
            s.supervisorSignature = true;
            s.supervisorComment = comment;
            s.supervisorSignTime = Date.now();
          }
          return s;
        });
        this.gtmTrackSign(sectionToBeUpdated.sectionType);
        await this.saveActionPdp(pdp);
      }
    },
    gtmTrackSign(sectionType) {
      this.$gtm.trackEvent({
        event: null,
        category: 'SupervisorAction',
        action: 'Supervisor Sign Pdp',
        label: 'OrientationBanner',
        value: `${sectionType}`,
      });
    },
    async getNotes() {
      if (
        !this.personalNotes ||
        (this.personalNotes && this.personalNotes.pdp_id !== this.actionPdp.id)
      ) {
        await this.getPersonalNotes(this.actionPdp.id);
      }
    },
    async openNotes() {
      await this.getNotes();
      this.notesOpen = true;
      this.$emit('notesOpen', this.notesOpen);
    },
    async updateNotes() {
      await this.updatePersonalNotes(this.personalNotes);
      this.notesOpen = false;
      this.$emit('notesOpen', this.notesOpen);
    },
    async closeNotes() {
      this.notesOpen = false;
      this.$emit('notesOpen', this.notesOpen);
    },
    closeExpiredPdpDialog() {
      this.$emit('handleRefresh');
    },
  },
  emits: ['handleRefresh'],
};
</script>

<style lang="scss" scoped>
// use pre-defined ADS scss variables
@import '../../scss/variables';

@media screen and (max-width: 959px) {
  :deep .v-slide-group .v-slide-group__prev {
    &--disabled {
      display: none !important;
    }
  }
  :deep .v-expansion-panel-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>

<template>
  <div>
    <h2 class="headline font-weight-bold mb-4 ads-grey-01">
      {{ sectionTitle }}
    </h2>
    <v-row class="mb-6">
      <v-col
        v-for="tile in tiles"
        :key="tile.title"
        cols="12"
        md="6"
      >
        <v-card class="d-flex flex-column pa-6 relative fill-height">
          <a
            class="text-decoration-none"
            :href="tile.url"
            target="_blank"
          >
            <h3 class="mb-3 title font-weight-bold ads-primary-blue">
              {{ tile.title }}
            </h3>
          </a>
          <div class="d-flex">
            <div
              class="pr-10 body-2 ads-grey-02 tile--description"
              v-if="tile.description"
              v-html="tile.description"
            />
            <a
              class="tile--icon text-decoration-none"
              :href="tile.url"
              target="_blank"
            >
              <v-icon class="mr-6 ads-grey-01">mdi-launch</v-icon>
            </a>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TilesSection',
  props: {
    sectionTitle: {
      type: String,
      required: true,
    },
    tiles: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}
.tile--icon {
  position: absolute;
  // vertical alignment within tile takes icon height (24px) into account
  top: calc(50% - 12px);
  right: 0;
}
.tile--description {
  line-height: 24px !important;
}
</style>

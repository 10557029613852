<template>
  <v-card class="pa-8">
    <div>
      <p>
        <strong class="title">
          <v-icon
            color="success"
            class="mr-2"
          >
            mdi-message-reply-text-outline
          </v-icon>
          PDP Supervisor's Comments
        </strong>
      </p>
    </div>
    <p>
      Include achievements, any challenges, what worked, and any areas for
      future development.
    </p>
    <RichTextField
      v-model.trim="finalSection.supervisorComment"
      label="Add details"
      :placeholder="!readOnly ? 'Add a comment...' : null"
      :disabled="readOnly"
      :rules="[
        rules.stringLengthRequired(ANNUAL_REVIEW_MIN_LENGTH),
        rules.maxStringLengthRule(ANNUAL_REVIEW_MAX_LENGTH),
      ]"
    />
    <AdsButton
      buttonText="Save"
      :disabled="!hasCommentChanged"
      icon="mdi-arrow-right"
      @click="handleSaveAnnualReviewSupervisorComment"
    />

    <v-card
      class="mt-8 pa-10"
      v-if="displayNotifyPanel"
    >
      <p>
        Confirm you are ready to make your annual review comments available to:
      </p>
      <div class="d-flex mb-7">
        <InitialsIcon
          class="mr-4"
          :givenName="superviseeDetails.displayedName"
          size="42px"
        ></InitialsIcon>
        <div>
          <div>
            <strong>{{ superviseeDetails.displayedName }}</strong>
          </div>
          <strong>{{ superviseeDetails.role }}</strong>
        </div>
      </div>
      <AdsButton
        buttonText="Proceed"
        icon="mdi-arrow-right"
        type="submit"
        @click="handleProceed"
      />
    </v-card>
  </v-card>
</template>

<script>
import { AdsButton, InitialsIcon } from '@nswdoe/doe-ui-core';
import validators from '@/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import { equals } from 'ramda';
import { formatName } from '@/utils';
import {
  ANNUAL_REVIEW_MAX_LENGTH,
  ANNUAL_REVIEW_MIN_LENGTH,
  SECTION_TYPE,
} from '@/data/constants';
import { resetSignatures } from '@/lib/pdp';
import RichTextField from '@/components/RichTextField.vue';

export default {
  name: 'NTSSupervisorAnnualReviewComment',
  components: {
    RichTextField,
    AdsButton,
    InitialsIcon,
  },
  data() {
    return {
      rules: {
        maxStringLength: validators.maxStringLength,
        stringLengthRequired: validators.stringLengthRequired,
        maxStringLengthRule: validators.maxStringLengthRule,
      },
      originalComment: '',
      copyOnStartup: true,
      hasCommentChanged: false,
      ANNUAL_REVIEW_MIN_LENGTH,
      ANNUAL_REVIEW_MAX_LENGTH,
    };
  },
  props: {
    isPanelOn: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    supervisorComment: {
      handler() {
        if (this.copyOnStartup) {
          this.originalComment = this.finalSection?.supervisorComment;
          this.copyOnStartup = false;
        }
        this.hasCommentChanged = !equals(
          this.originalComment,
          this.finalSection?.supervisorComment
        );
        this.$emit('proceed', false);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['actionPdp']),
    ...mapGetters('pdp', {
      finalSection: 'actionPdpFinalSection',
    }),
    superviseeDetails() {
      const supervisee = this.actionPdp?.owner;
      return {
        givenName: supervisee?.staffGivenName,
        surname: supervisee?.staffFamilyName,
        displayedName: formatName(supervisee),
        role: this.actionPdp?.userPosition,
      };
    },
    supervisorComment() {
      return this.finalSection?.supervisorComment;
    },
    displayNotifyPanel() {
      return (
        this.finalSection?.supervisorComment &&
        !this.hasCommentChanged &&
        !this.isPanelOn &&
        this.finalSection?.notifySupervisor
      );
    },
  },
  methods: {
    ...mapActions('pdp', ['saveActionPdp']),
    async handleSaveAnnualReviewSupervisorComment() {
      this.actionPdp.sections = resetSignatures(
        SECTION_TYPE.final,
        this.actionPdp
      );
      await this.saveActionPdp(this.actionPdp);
      this.originalComment = this.finalSection?.supervisorComment;
      this.hasCommentChanged = false;
      this.$emit('proceed', false);
    },
    handleProceed() {
      this.$emit('proceed', true);
    },
  },
};
</script>

<template>
  <v-navigation-drawer
    v-bind="$attrs"
    v-on="$listeners"
    v-model="drawer"
    :app="app"
    :clipped="clipped"
    :width="width"
    :color="color"
    :mini-variant.sync="mini"
    :mini-variant-width="miniVariantWidth"
  >
    <div
      v-if="$vuetify.breakpoint.smAndDown && showProfileMobile"
      class="navigation-drawer-mobile"
    >
      <v-expansion-panels
        flat
        :value="[0]"
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="d-flex align-end pr-5 pt-5">
            <div id="nav-bar--user-details">
              <InitialsIcon :givenName="profileName" />
              <h2 class="pt-2 subtitle-1">
                {{ profileName }}
              </h2>
              <v-list-item-subtitle class="pt-1 ads-dark-60 caption">
                {{ profileJobTitle }}
              </v-list-item-subtitle>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-list-item
                tabindex="1"
                v-for="(item, i) in profileMenuItems"
                :key="i"
                :to="item.route"
                :href="item.href"
                :target="item.target"
                @click="item.clickHandler ? item.clickHandler() : noop()"
                :id="item.id"
              >
                <v-list-item-icon>
                  <v-icon
                    v-if="item.icon && !item.notificationCount"
                    size="28"
                    :class="{ 'material-icons-outlined': item.iconOutlined }"
                  >
                    {{ item.icon }}
                  </v-icon>
                  <div
                    class="notification-counter d-flex align-center justify-center"
                    v-if="item.notificationCount"
                  >
                    <span>{{ item.notificationCount }}</span>
                  </div>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="$emit('logout')"
                class="mt-2"
              >
                <v-list-item-icon>
                  <v-icon class="nav-bar--logout-icon">
                    mdi-logout-variant
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="nav-bar--logout-text">
                      Log out of all DoE apps
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <FocusTrap :isActive="$vuetify.breakpoint.mdAndDown">
      <div class="navigation-drawer__items">
        <!-- slot "preMenu" is part of NavigationDrawer component       -->
        <slot name="preMenu" />
        <v-list
          nav
          class="mt-2"
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.route"
            :href="item.href"
            :target="item.target"
            :exact="!!item.exact"
            @click="item.clickHandler ? item.clickHandler() : noop()"
            class="justify-start align-center"
            :class="{
              'px-3': !item.items,
              'px-0': item.items,
              'expandable': item.items,
              'mb-1': !item.items,
            }"
            :id="item.id"
            :ripple="!isGroupHeader(item)"
            :tabindex="isGroupHeader(item) ? -1 : 0"
          >
            <!-- single menu item -->
            <v-row
              v-if="!item.items"
              no-gutters
            >
              <v-list-item-icon>
                <v-icon
                  v-if="item.icon && !item.notificationCount"
                  size="28"
                  :class="{ 'material-icons-outlined': item.iconOutlined }"
                >
                  {{ item.icon }}
                </v-icon>
                <div
                  class="notification-counter d-flex align-center justify-center"
                  v-if="item.notificationCount"
                >
                  <span>{{ item.notificationCount }}</span>
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-row>

            <!-- Expandable group sections -->
            <v-row
              v-if="item.items"
              no-gutters
              class="align-center nav-bar--menu-group"
            >
              <v-expansion-panels
                accordion
                flat
                v-model="expansion"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="py-0 px-3"
                    focusable
                  >
                    <v-row no-gutters>
                      <v-list-item-icon>
                        <v-icon
                          size="28"
                          :class="{
                            'material-icons-outlined': item.iconOutlined,
                          }"
                        >
                          {{ item.icon }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content :class="{ 'd-none': mini }">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-row>
                    <template #actions>
                      <v-icon
                        class="icon"
                        :class="{ 'd-none': mini }"
                      >
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="mb-1">
                    <v-list nav>
                      <v-list-item
                        class="mt-1"
                        v-for="subItem in item.items"
                        :key="subItem.title"
                        :to="subItem.route"
                        :id="subItem.id"
                        :exact="!!item.exact"
                        @click="
                          subItem.clickHandler ? subItem.clickHandler() : noop()
                        "
                      >
                        <v-list-item-content>
                          {{ subItem.title }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-list-item>
        </v-list>
        <!-- slot "postMenu" is part of NavigationDrawer component       -->
        <slot name="postMenu" />
      </div>
    </FocusTrap>
  </v-navigation-drawer>
</template>

<script>
import { FocusTrap, InitialsIcon } from '@nswdoe/doe-ui-core';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';

export default {
  name: 'NavigationDrawer',
  components: {
    InitialsIcon,
    FocusTrap,
  },
  props: {
    color: {
      type: String,
      default: ADS_Colors.White, // override the default value 'undefined' in v-navigation-drawer
    },
    width: {
      type: [Number, String],
      default: '300', // override the default value '256' in v-navigation-drawer
    },
    clipped: {
      type: Boolean,
      default: true, // override the default value 'false' in v-navigation-drawer
    },
    items: {
      type: Array,
      default: () => [],
    },
    showProfileMobile: {
      type: Boolean,
      default: false,
    },
    profileName: {
      type: String,
      default: '',
    },
    profileJobTitle: {
      type: String,
      default: '',
    },
    profileMenuItems: {
      type: Array,
      default: () => [],
    },
    app: {
      type: Boolean,
      default: true, // override the default value 'false' in v-navigation-drawer
    },
    miniVariantWidth: {
      type: [Number, String],
      default: 70, // override the default value '56' in v-navigation-drawer
    },
  },
  data() {
    return {
      drawer: this.$vuetify.breakpoint.lgAndUp,
      panels: {},
      expansion: 0,
      mini: false,
      panelHistory: {},
    };
  },
  mounted() {
    // adding eventHub listener
    this.$eventHub.$on('toggle:navigation', this.toggleNavigation);
  },
  beforeDestroy() {
    // removing eventHub listener
    this.$eventHub.$off('toggle:navigation');
  },
  methods: {
    toggleNavigation() {
      // only use mini drawer for desktops
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.drawer = !this.drawer;
        this.mini = false;
      } else {
        this.mini = !this.mini;
        // when opening the navigationDrawer, set panels to the saved history
        if (!this.mini) {
          this.panels = this.panelHistory;
        }
      }
    },
    noop() {
      return;
    },
    isGroupHeader(item) {
      return item.items != null && item.items.length > 0;
    },
    closeAllPanels() {
      if (this.mini) {
        // save panel history when navigationDrawer is minified
        this.panelHistory = this.panels;
        // close all panels by emptying the model object
        this.panels = {};
      }
    },
  },
  watch: {
    drawer: {
      immediate: true,
      handler(val) {
        if (val === false && this.$vuetify.breakpoint.mdAndDown) {
          this.$eventHub.$emit('closed:navigation');
        }
      },
    },
    mini: function () {
      this.closeAllPanels();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@nswdoe/doe-ui-core/styles/variables.scss';

.row {
  margin-top: 0;
  margin-bottom: 0;
}

.navigation-drawer-mobile {
  margin-left: 0;
  margin-bottom: 12px;
  padding-right: 4px;
  border-bottom: 1px solid lightgray;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}

.v-navigation-drawer {
  .v-list {
    padding: 0;

    .v-list-item {
      margin: 0 8px;
      border-radius: 4px;
      min-height: 48px;

      &:before {
        opacity: 0;
      }

      &.v-list-item--active {
        &:before {
          opacity: 0;
        }
      }

      &.v-list-item--link:not(.expandable) {
        border: 1px solid transparent;
      }

      .v-list-item__content {
        margin-left: 21px;

        .v-list-item__title {
          overflow: visible;
          line-height: 1;
        }
      }

      .v-list-item__icon {
        margin: 0;
        align-self: auto;
      }
    }
  }
}

.v-navigation-drawer.theme--light .v-list .v-list-item {
  color: $ads-dark !important;

  // active state
  &.v-list-item--active {
    .theme--light.v-icon {
      color: $ads-navy;
    }

    .v-list-item__content {
      color: $ads-navy !important;

      .v-list-item__title {
        color: $ads-navy !important;
      }
    }

    background-color: $ads-custom-navy-2;
  }

  // default states
  .theme--light.v-icon {
    color: $ads-dark;
  }

  .v-list-item__content {
    color: $ads-dark !important;

    .v-list-item__title {
      color: $ads-dark !important;
    }
  }

  .v-list-item__icon {
    color: $ads-dark;
  }
}

.v-navigation-drawer.theme--dark {
  background-color: $ads-dark !important;

  .v-list {
    .v-list-item {
      &:not(.expandable):hover {
        background-color: $ads-06dp;
      }
    }

    // active state
    .v-list-item--active {
      background-color: $ads-navy-dark !important;
      color: $ads-dark;
    }

    .v-list-item--link {
      &:focus {
        border-color: $ads-navy-dark;
      }
    }
  }
}

.nav-bar--menu-group .v-item-group.v-expansion-panels {
  z-index: 0;

  .v-expansion-panel-header {
    border-radius: 4px;
    margin: 0px;
    padding-right: 16px;
    border: 1px solid transparent;

    &:hover {
      background-color: $ads-light-20;
    }

    &:focus {
      background-color: rgba(0, 0, 0, 0);
      border-color: $ads-navy;
    }

    &:before {
      background-color: transparent;
    }

    &.v-expansion-panel-header--active {
      // override parent's min-height to default v-list-item height
      min-height: 48px;
    }

    ::v-deep .v-expansion-panel-header__icon .v-icon {
      color: $ads-dark;
    }
  }

  .v-expansion-panel-content .v-list-item {
    margin-left: 0px;
    margin-right: 0px;

    .v-list-item__content {
      padding-left: 33px;
    }
  }
}

.v-navigation-drawer.theme--dark .v-expansion-panels {
  background-color: transparent !important;

  .v-expansion-panel {
    background-color: transparent !important;
  }

  .v-expansion-panel-header {
    background-color: $ads-dark;

    &:hover {
      background-color: $ads-06dp !important;
    }

    &:focus {
      border-color: $ads-navy-dark;
    }

    &.v-expansion-panel-header--active {
      background-color: transparent;
    }

    ::v-deep .v-expansion-panel-header__icon .v-icon {
      color: $ads-light-20 !important;
    }
  }
}

.v-navigation-drawer.theme--light .v-list {
  .v-list-item.v-list-item--link:not(.expandable):hover {
    background-color: $ads-light-20;
  }

  .v-list-item.v-list-item--link:not(.expandable) {
    &:focus {
      border-color: $ads-navy;
    }
  }
}

.notification-counter {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: $ads-error-red;
  color: white;
  font-size: 16px;
}

.nav-bar--logout-text,
.nav-bar--logout-icon {
  color: $ads-error-red !important;
}
</style>

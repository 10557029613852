<template>
  <v-row>
    <v-col>
      <ErrorPage
        class="container"
        title="The page you are looking for does not exist"
        :is404Page="true"
        :actions="[
          {
            text: 'go back to home.',
            attrs: {
              href: '/',
              rel: 'noopener',
            },
          },
        ]"
      />
    </v-col>
  </v-row>
</template>

<script>
import ErrorPage from '@nswdoe/doe-ui-core/src/ErrorPage/ErrorPage.vue';

export default {
  name: 'NotFound',
  components: {
    ErrorPage,
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 12px;
  padding-top: 20vh;
  padding-bottom: 20vh;
}
</style>

<template>
  <v-container :white="isMobile">
    <slot v-if="isMobile"></slot>
    <v-card
      v-else
      :class="cardClass"
    >
      <slot></slot>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Wrapper',
  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
    cardClass: {
      type: String,
      default: '',
    },
  },
};
</script>

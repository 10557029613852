import {
  Accountability,
  Goal,
  Pdp,
  Section,
  Standard,
} from '../../../../lib/schema';
import { completeSection } from '@/lib/pdp';

export const planIncompleteMessage = (planSection: Section) => {
  let goalMissingAccountability = undefined;
  if (planSection)
    goalMissingAccountability = planSection?.goals?.some(
      (goal) => !goal.accountabilities || goal.accountabilities.length === 0
    );
  if (goalMissingAccountability) {
    return 'You must add at least one Key Accountability to each goal before you can mark this phase as complete.';
  }
  return '';
};

export const setNotifySupervisor = (
  planSection: Section,
  sections: Section[]
) => {
  planSection.notifySupervisor = true;
  planSection.notifySupervisorTime = Date.now();
  sections = sections.map((s) => {
    if (s.id === planSection?.id) {
      return planSection;
    }
    return s;
  });
  return sections;
};

export const signSectionOwner = (
  sectionToBeUpdated: Section,
  comment: string,
  formPdp: Pdp
) => {
  formPdp.sections = formPdp.sections?.map((section) => {
    if (section.sectionType === sectionToBeUpdated.sectionType) {
      section.userSignature = true;
      section.userComment = comment;
      section.userSignTime = Date.now();
    }
    return section;
  });
  return formPdp;
};

export const copyTSPlanToMidSection = (
  sectionToBeUpdated: Section,
  formPdp: Pdp
) => {
  let planSectionGoals: Goal[] = [];
  const isPlanSectionBeingUpdated = sectionToBeUpdated.sectionType === 'PLAN';
  if (isPlanSectionBeingUpdated && sectionToBeUpdated.goals) {
    planSectionGoals = [...sectionToBeUpdated.goals];
  }

  formPdp.sections = formPdp.sections?.map((section) => {
    /**
     * If the section we are updating is the plan section
     * check that MID_YEAR section does not have any goals
     * If it does not then clone the goals from Plan section into mid_year section
     */
    const isMidYearSection = section.sectionType === 'MID_YEAR';

    if (isMidYearSection && section.goals && section.goals.length === 0) {
      section.goals = planSectionGoals?.map((goal: Goal) => {
        const standards = goal.standards?.map((standard: Standard) => ({
          ...standard,
          id: null,
          goal_id: null,
        }));

        const accountabilities: Accountability[] = goal.accountabilities?.map(
          (accountability) => ({
            ...accountability,
            id: null,
            goal_id: null,
          })
        ) as unknown as Accountability[];

        return {
          ...goal,
          id: null,
          standards,
          accountabilities,
          section_id: section.id as unknown as number,
        };
      }) as unknown as Goal[];
    }
    return section;
  });
  return formPdp;
};

export const signTSPlanAsOwner = (
  sectionToBeUpdated: Section,
  comment: string,
  formPdp: Pdp
) => {
  formPdp = signSectionOwner(sectionToBeUpdated, comment, formPdp);
  formPdp = completeSection(sectionToBeUpdated, formPdp);
  return copyTSPlanToMidSection(sectionToBeUpdated, formPdp);
};

<template>
  <div>
    <v-row
      class="mx-0 mx-md-n3 px-5 px-md-0"
      justify="space-between"
    >
      <v-col class="px-0 px-md-3">
        <h4 class="display-1">Review</h4>
      </v-col>
    </v-row>
    <v-row class="px-5 px-md-0">
      <v-col>
        <p>
          The focus of this phase is to review your progress and achievement
          through reflective practice and explicit, constructive feedback.
        </p>
        <ul>
          <li>
            A self-assessment review should be completed mid-way through the
            annual cycle.
          </li>
          <li>
            The annual review should be completed towards the end of the
            performance and development cycle.
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <AdsExpansionPanel
          v-model="reviewPanelIndex"
          :items="panelSections"
          multiple
        >
          <template #contentmid-year>
            <TSProgressReport :readOnly="isPdpArchived" />
          </template>
          <template #contentannual>
            <TSAnnualReview
              v-if="isMidYearSectionComplete || isPdpArchived"
              :readOnly="isPdpArchived"
            />
            <v-container
              v-else
              class="px-5 px-md-3"
            >
              <v-row class="pl-0 pl-md-4 py-2 align-center">
                <v-col class="flex-grow-0">
                  <v-icon :color="ADS_Colors.Error_Red">
                    mdi-alert-circle
                  </v-icon>
                </v-col>
                <v-col class="pl-0 flex-grow-1">
                  <p class="mb-0">
                    You must complete your
                    <strong>mid-year self-assessment</strong>
                    before you can proceed to your annual review
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </AdsExpansionPanel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import AdsExpansionPanel from '@nswdoe/doe-ui-core/src/AdsExpansionPanel/AdsExpansionPanel.vue';
import TSProgressReport from '@/components/TSProgressReport.vue';
import TSAnnualReview from '@/components/TSAnnualReview.vue';
import { mapGetters } from 'vuex';
import { PANEL_TYPES } from '@/data/constants';

export default {
  name: 'TSReviews',
  components: {
    TSAnnualReview,
    AdsExpansionPanel,
    TSProgressReport,
  },
  data() {
    return {
      ADS_Colors,
      panelSections: [PANEL_TYPES.midYear, PANEL_TYPES.annual],
      reviewPanelIndex: [0],
    };
  },
  computed: {
    ...mapGetters(['isMidYearSectionComplete', 'isPdpArchived']),
  },
  watch: {
    isMidYearSectionComplete: {
      handler: function () {
        if (this.isMidYearSectionComplete) {
          const annualReviewPanelSectionIndex = this.panelSections.findIndex(
            (section) => section['id'] === PANEL_TYPES.annual.id
          );
          if (!this.reviewPanelIndex.includes(annualReviewPanelSectionIndex)) {
            this.reviewPanelIndex.push(annualReviewPanelSectionIndex);
          }
        }
      },
      immediate: true,
    },
    isPdpArchived: {
      handler() {
        if (this.isPdpArchived) {
          this.reviewPanelIndex = [0, 1];
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 959px) {
  .max-width-none {
    max-width: none;
  }
  :deep .v-expansion-panel-header.panel {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>

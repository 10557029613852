/**
 * https://ssr.vuejs.org/guide/head.html
 * @param vm
 * @returns {*}
 */
import Vue, { ComponentOptions, VueConstructor } from 'vue';

interface VueInstanceWithTitle extends Vue {
  $options: ComponentOptions<Vue> & {
    title?: string | (() => string);
  };
}

function getTitle(vm: VueInstanceWithTitle): string | undefined {
  // components can simply provide a `title` option
  // which can be either a string or a function
  const { title } = vm.$options;
  if (title) {
    return typeof title === 'function' ? title.call(vm) : title;
  }
}

const titleMixin = {
  created(this: VueInstanceWithTitle) {
    const title = getTitle(this);
    if (title) {
      window.document.title = title;
    }
  },
};

export default titleMixin as unknown as VueConstructor<Vue>; // or `export default Vue.extend(titleMixin);`

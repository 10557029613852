<template>
  <v-container>
    <Dialog
      content-class="mx-0 mx-md-6"
      :openDialog="isModalOpen"
      title="Notify your PDP supervisor"
      :maxWidth="$vuetify.breakpoint.smAndDown ? '100vw' : '50vw'"
      icon="security"
      :displayCloseBtn="true"
      :actions="confirmationActions"
      @close="handleDialogReturn"
      :return-handler="handleDialogReturn"
    >
      <template #text>
        <v-container class="px-6 pt-0">
          <v-row no-gutters>
            <v-col cols="12">
              <p
                class="body-1"
                v-if="section?.sectionType === 'PLAN'"
              >
                Notify your PDP supervisor that you are ready to discuss your
                PDP.
              </p>
              <p
                class="body-1"
                v-if="section?.sectionType === 'MID_YEAR'"
              >
                Notify your PDP supervisor that you have completed your
                self-assessment.
              </p>
              <p
                class="body-1"
                v-if="section?.sectionType === 'FINAL'"
              >
                Notify your PDP supervisor that you are ready to discuss your
                PDP.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </Dialog>
    <Dialog
      maxWidth="40em"
      :openDialog="openSuccessfulModal"
      :displayCloseBtn="true"
      @close="openSuccessfulModal = false"
    >
      <template #text>
        <v-container>
          <v-row class="text-center px-12">
            <v-col
              cols="12"
              class="d-flex py-0 justify-center"
            >
              <FeatureIcon
                size="161"
                iconSize="70"
                color="black"
                backgroundColor="light blue"
                icon="mdi-check"
              >
                <template #icon />
              </FeatureIcon>
            </v-col>
            <v-col cols="12">
              <h2 class="headline mt-3">Notification sent</h2>
            </v-col>
            <v-col cols="12">
              <p
                class="body-1"
                v-if="section?.sectionType === 'PLAN'"
              >
                Book in a meeting with your PDP supervisor to discuss your PDP.
              </p>
              <p
                class="body-1"
                v-if="section?.sectionType === 'MID_YEAR'"
              >
                Your PDP supervisor has been notified that your self-assessment
                has been completed.
              </p>
            </v-col>
            <v-col cols="12">
              <AdsButton @click="onSuccessClose">Continue</AdsButton>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import Dialog from '@nswdoe/doe-ui-core/src/Dialog/Dialog.vue';
import AdsButton from '@nswdoe/doe-ui-core/src/AdsButton/AdsButton.vue';
import FeatureIcon from '@nswdoe/doe-ui-core/src/FeatureIcon/FeatureIcon.vue';

export default {
  name: 'MarkCompleteModal',
  props: {
    isModalOpen: Boolean,
    section: {
      type: Object,
      default: null,
    },
    showEditGoalsInfo: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Dialog,
    AdsButton,
    FeatureIcon,
  },
  data() {
    return {
      confirmationActions: [
        { name: 'Cancel', color: '#041E42', size: 'large', outlined: true },
        { name: 'Submit', color: '#041E42', size: 'large', outlined: false },
      ],
      openSuccessfulModal: false,
    };
  },
  methods: {
    onSuccessClose() {
      this.openSuccessfulModal = false;
      document.getElementById('orientation')?.scrollIntoView({
        behavior: 'smooth',
      });
    },
    async handleDialogReturn(result) {
      if (result === 'Submit') {
        this.$emit('success');
        // setTimeout(() => {
        //   this.openSuccessfulModal = true;
        // }, 300);
      }
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <v-card
    :color="ADS_Colors.Light_10"
    elevation="0"
  >
    <v-row
      align="center"
      class="pl-4"
    >
      <v-col>
        <div
          v-if="file?.observer"
          class="ma-2"
        >
          <p class="title">{{ file.title }}</p>
          <p>
            <strong class="subtitle-1">Observer:</strong>
            {{ file.observer?.staffPreferredName ?? file.observer?.name }}
          </p>
        </div>
        <div class="d-flex flex-column flex-md-row justify-space-between ma-2">
          <div>
            <p class="ma-0 pa-0">
              <v-icon>mdi-paperclip</v-icon>
              {{ file?.fileName }}
            </p>
          </div>
          <div class="mt-3 mt-sm-0 text-right">
            <AdsButton
              v-if="download"
              :ripple="false"
              buttonText="Download"
              class="mx-1 pa-0 px-2"
              icon="mdi-download-outline"
              tertiary
              @click="$emit('downloadFile')"
            />
            <AdsButton
              v-if="editMode"
              :ripple="false"
              buttonText="Delete"
              class="mx-1 pa-0 px-2"
              color="error"
              icon="mdi-delete-outline"
              tertiary
              @click="$emit('deleteFile')"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { AdsButton } from '@nswdoe/doe-ui-core';

export default {
  name: 'FileCard',
  components: { AdsButton },
  props: {
    file: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ADS_Colors: ADS_Colors,
    };
  },
};
</script>

import { render, staticRenderFns } from "./SuperviseeTable.vue?vue&type=template&id=32d36e39&scoped=true"
import script from "./SuperviseeTable.vue?vue&type=script&lang=js"
export * from "./SuperviseeTable.vue?vue&type=script&lang=js"
import style0 from "./SuperviseeTable.vue?vue&type=style&index=0&id=32d36e39&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32d36e39",
  null
  
)

export default component.exports